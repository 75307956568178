
import { defineStore } from "pinia";
import CartItem from '~/types/CartItem';
import { useLocalStorage } from "@vueuse/core";

export const useCartStore = defineStore('cart',
    {
        state: () => ({
            cart: useLocalStorage('cart', [] as Array<CartItem>),
            showPopupCart: false as boolean | undefined,
            showPopupCloseModal: false as boolean | undefined,
        }),
        hydrate(state, initialState) {
            // in this case we can completely ignore the initial state since we
            // want to read the value from the browser
            state.cart = useLocalStorage('cart', state.cart).value;
        },
        actions: {
            addCartItem(cartItem: CartItem) {
                this.cart.push(cartItem);
            },
            getCartItem(cartItemGuid: string): CartItem | undefined {
                return this.cart.find((c) => c.cartItemGuid === cartItemGuid);
            },
            get(index: number): CartItem | undefined {
                return this.cart[index];
            },
            clearCart(): void {
                this.cart = [];
            },
            updateCartItem(cartItemToUpdate: CartItem): void {
                const indexToChange = this.cart.findIndex((c) => c.cartItemGuid === cartItemToUpdate.cartItemGuid);
                if (indexToChange > -1) {
                    this.cart.splice(indexToChange, 1, cartItemToUpdate);
                    localStorage.setItem("cart", JSON.stringify(this.cart));
                }
            },
            removeCartItem(cartItemGuid: string) {
                let index = this.cart.findIndex(c => c.cartItemGuid == cartItemGuid);
                if (index > -1) {
                    this.cart.splice(index, 1);
                    localStorage.setItem("cart", JSON.stringify(this.cart));
                }
            },
            toggleShowCart(): void {
                this.showPopupCart = !this.showPopupCart;
            },
            showCloseModal(): void {
                this.showPopupCloseModal = !this.showPopupCloseModal;
            },
            count() { return this.cart.length; }
        }
    })
